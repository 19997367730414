// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-en-careers-tsx": () => import("./../../../src/pages/en/careers.tsx" /* webpackChunkName: "component---src-pages-en-careers-tsx" */),
  "component---src-pages-en-company-tsx": () => import("./../../../src/pages/en/company.tsx" /* webpackChunkName: "component---src-pages-en-company-tsx" */),
  "component---src-pages-en-kuromoji-tsx": () => import("./../../../src/pages/en/kuromoji.tsx" /* webpackChunkName: "component---src-pages-en-kuromoji-tsx" */),
  "component---src-pages-en-products-tsx": () => import("./../../../src/pages/en/products.tsx" /* webpackChunkName: "component---src-pages-en-products-tsx" */),
  "component---src-pages-en-services-tsx": () => import("./../../../src/pages/en/services.tsx" /* webpackChunkName: "component---src-pages-en-services-tsx" */),
  "component---src-pages-en-tsx": () => import("./../../../src/pages/en.tsx" /* webpackChunkName: "component---src-pages-en-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-ja-careers-tsx": () => import("./../../../src/pages/ja/careers.tsx" /* webpackChunkName: "component---src-pages-ja-careers-tsx" */),
  "component---src-pages-ja-company-tsx": () => import("./../../../src/pages/ja/company.tsx" /* webpackChunkName: "component---src-pages-ja-company-tsx" */),
  "component---src-pages-ja-kuromoji-tsx": () => import("./../../../src/pages/ja/kuromoji.tsx" /* webpackChunkName: "component---src-pages-ja-kuromoji-tsx" */),
  "component---src-pages-ja-products-tsx": () => import("./../../../src/pages/ja/products.tsx" /* webpackChunkName: "component---src-pages-ja-products-tsx" */),
  "component---src-pages-ja-services-tsx": () => import("./../../../src/pages/ja/services.tsx" /* webpackChunkName: "component---src-pages-ja-services-tsx" */),
  "component---src-pages-ja-tsx": () => import("./../../../src/pages/ja.tsx" /* webpackChunkName: "component---src-pages-ja-tsx" */)
}

